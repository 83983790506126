import Vue from 'vue';

export default {
    async findTimbresIlimitados(parametros) {
        return Vue.axios.get("cliente-libre-datos/find", { params: parametros }).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    async guardarDatos(parametros) {
        return Vue.axios.post("cliente-libre-datos/guardar", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    async updateDatos(parametros) {
        return Vue.axios.post("cliente-libre-datos/update", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    async getUsoCFDI(parametros) {
        return Vue.axios.get("catalogo-sat/uso-cfdi/find", { params: parametros }).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    async guardarFactura(parametros){
        return Vue.axios.post("facturacion-timbres-libres/estado-cuenta", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    async getHistorial(id){
        return Vue.axios.get("facturacion-timbres-libres/historial/"+id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    async actualizarEstatus(parametros) {
        return Vue.axios.post("facturacion-timbres-libres/cambiar-estatus", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
}