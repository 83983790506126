<template>
    <div>
        <v-row class="ma-0 pa-0">
            <v-card class="paquete-card" elevation="0">
                <div class="paquete-contenedor">
                    <h3 class="paquete-titulo">
                        {{paquete.nombre}}
                    </h3>
                    <p class="paquete-descripcion">
                        {{paquete.descripcion}}
                    </p>

                    <div class="paquete-timbre d-flex justify-center">
                        <span>
                            {{paquete.timbres}} Timbres
                        </span>
                    </div>

                    <div class="paquete-total d-flex justify-center">
                        Total:
                    </div>

                    <div class="paquete-precio d-flex justify-center">
                        {{formatNum(paquete.precio)}} MXN
                    </div>

                    <v-btn class="paquete-btn mt-15" :class="claseColor" @click="enviarDatos()" elevation="0">
                        Comprar ahora
                    </v-btn>
                </div>
            </v-card>
        </v-row>
    </div>
</template>

<script>
export default {
    props:{
        paquete: {
            type: Object,
            default: () => ({
                id:null,
                clave: '',
                nombre: 'PAQUETE BASIC',
                descripcion: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut eaque facere reprehenderit? Blanditiis reprehenderit voluptas esse debitis delectus facere perspiciatis.',
                timbres: '10000',
                precio: '1000'
            }),
        },
        claseColor: {
            type: String,
            default: 'color-azul-claro'
        }
    },
    data() {
        return {


        }
    },
    methods:{
        /**
         * @method formatNum Esta método parsea un número
         * @description Convierte un número a formato moneda, formato pesos mexicanos 
         * @param {number} num Valor númerico que puede tener números decimales.
         * @return {string} devuelve un string en formato $0.00
        */
        formatNum(numero){
            let num = parseFloat(numero)
            const formatter = new Intl.NumberFormat('es-MX', {
                style: 'currency',
                currency: 'MXN',
            })
            return formatter.format(num);
        },
        enviarDatos(){
            this.$emit("datosPaquete",this.paquete)
        }
    }
}
</script>

<style>
    .paquete-card {
        width: 360px;
        height: 480px;
        border-radius: 20px !important;
        border: 1px solid #BDBDBD !important;
    }
    .paquete-contenedor {
        margin: 30px;
    }
    .paquete-titulo {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 18px;
        color: #1E2245;

    }
    .paquete-descripcion {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #828282;
        margin-top:15px ;
    }
    .paquete-timbre {
        border: 1px solid #E0E0E0;
        border-radius: 10px;
        margin-top: 40px;
    }

    .paquete-timbre span {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 27px;
        color: #828282;
        margin: 25px 0;
    }
    .paquete-total {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: #828282;
        margin-top: 40px
    }
    .paquete-precio {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 27px;
        color: #1E2245;
        margin-top: 5px
    }

    .paquete-btn.v-btn {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #FFFFFF;
        width: 100%;
        border-radius: 80px;
        text-transform: none;
        
    }
    .color-azul-claro.v-btn{
        background-color: #2DB9D1!important;
    }
    .color-azul-oscuro.v-btn{
        background-color: #1E2245!important;
    }

</style>