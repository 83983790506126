<template>
    <div id="pageTable">
        <v-container 
            class="d-flex align-center justify-center" 
            style="height: 80vh; width: 80vw;"
            v-if="rol != 'root'"
        >
			<v-card class="" style="border-radius:20px" max-width="500">
                <v-card-title class="justify-center card_titulo">
                    <div class="headerModal">
                        <img :src="'/static/icon/peligro.svg'" class="imgModal"/>
                    </div>
                </v-card-title>
                <v-card-text class="titleModal text-center">
                    Esta vista no esta disponible para este rol de usuario.
                </v-card-text>
                <v-card-actions class="d-flex align-center justify-center card_accion">
                    <v-btn
                        class="btnGuardar"
                        @click="redireccionar()"
                    >
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
		</v-container>      
        <v-container xl fluid v-else>
            <v-row>
                <v-col cols="12" lg="12">
                    <data-table
                        ref="tabla"
                        :tableName="'Configuración de timbres'"
                        :url="url"
                        :columns="columns"
                        :filters="filters"
                        :showAdvancedFilters="false"
                        :perPage="[10,25,50,100]"
                    >
                        <template slot="actionButtonsLeft">
                            <div class="botonAgregar">
                                <v-btn
                                    rounded
                                    color="#004BAF"
                                    class="btnAdd"
                                    right
                                    @click="abrirModal('add')"
                                >
                                    <v-icon dark class="mr-2">add</v-icon>Agregar
                                    <div class="mr-4"></div>
                                </v-btn>
                            </div>
                        </template>
                        <tbody slot="body" slot-scope="{ data }">
                            <tr :key="item.id" v-for="item in data">
                                <expandable-item 
                                    :item="item"
                                    :key="item.id" 
                                    @detalle="detalle"
                                    @abrirModalUpdate="abrirModalUpdate"
                                    @facturar="facturar"
                                >
                                </expandable-item>
                            </tr>
                        </tbody>
                    </data-table>
                </v-col>
            </v-row>

            <template class="borde-card">
                <v-dialog v-model="dialog" persistent max-width="900px" class="borde-card">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                    </template>
                    <v-card class="borde-card">
                        <ValidationObserver ref="form" v-slot="{ invalid }">
                            <v-card-title class="justify-center card_titulo">
                                <div class="headerModal">
                                    <img :src="imagen" class="imgModal" />
                                    <h2 class="titleModal">{{ tituloModal }}</h2>
                                </div>
                            </v-card-title>

                            <v-card-text class="card_texto">
                                <div id="padre" class="d-flex justify-center my-10" v-if="isLoadingModal">
                                    <div id="loading">
                                        <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                    </div>
                                </div>
                                
                                <v-container v-if="!isLoadingModal" grid-list-md id="contenedor">
                                    <v-divider class="divider"></v-divider>
                                    <v-row>
                                        <v-col v-if="rol == 'root'" cols="12" xs="12" sm="12" md="12" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Cliente" rules="required">
                                                <v-autocomplete
                                                    outlined
                                                    :items="clientes"
                                                    item-text="nombre"
                                                    item-value="id"
                                                    label="Cliente"
                                                    persistent-hint
                                                    v-model="cliente_value"
                                                    :error-messages="errors"
                                                    no-data-text="Datos no disponibles"
                                                />
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="py-0">
                                            <VuetifyMultipleAutocomplete 
                                                outlined
                                                :items="itemsRegistrosPatronales"
                                                item-text="nombre"
                                                item-value="id"
                                                @dataMultiple="(data)=>configuracion.array_registro_patronal = data"
                                                :valorDefecto="configuracion.array_registro_patronal"
                                                label="Registros patronales"
                                                ref="multRegistroPatronal"
                                            />
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Razón social" rules="required">
                                                <v-text-field 
                                                    outlined
                                                    label="Razón social" 
                                                    class="pa-0 ma-0" 
                                                    v-model="configuracion.razon_social" 
                                                    :error-messages="errors" 
                                                    required
                                                >
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="RFC" rules="required|rfc">
                                                <v-text-field 
                                                    outlined 
                                                    label="RFC" 
                                                    class="pa-0 ma-0" 
                                                    maxlength="13"
                                                    v-model="configuracion.rfc" 
                                                    :error-messages="errors" 
                                                    required
                                                >                                               
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Régimen Fiscal" rules="required">
                                                <v-autocomplete
                                                    outlined
                                                    label="Régimen Fiscal"
                                                    :items="regimenesFiscales"
                                                    item-text="descripcion"
                                                    item-value="id"
                                                    persistent-hint
                                                    v-model="configuracion.SAT_regimen_fiscal_id"
                                                    class="pa-0 ma-0"
                                                    required 
                                                    :error-messages="errors" 
                                                    no-data-text="Datos no disponibles"
                                                >
                                                </v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Uso CFDI" rules="required">
                                                <v-autocomplete
                                                    outlined
                                                    label="Uso CFDI"
                                                    :items="itemsUsosCFDI"
                                                    item-text="descripcion"
                                                    item-value="id"
                                                    persistent-hint
                                                    v-model="configuracion.SAT_uso_cfdi_id"
                                                    class="pa-0 ma-0"
                                                    required 
                                                    :error-messages="errors" 
                                                    no-data-text="Datos no disponibles"
                                                >
                                                </v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>

                                        <v-col cols="12" xs="12" sm="6" md="4" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Código postal" rules="required">
                                                <v-text-field
                                                    outlined
                                                    label="Código postal"
                                                    v-model="configuracion.codigo_postal"
                                                    v-mask="'#####'"
                                                    required 
                                                    :error-messages="errors" 
                                                />
                                            </ValidationProvider>       
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions class="d-flex justify-end card_accion">
                                <button
                                    class="btnCerrar"
                                    @click="cerrarModal()" 
                                    :disabled="isSaving"
                                >
                                    Cancelar
                                </button>
                                <v-btn
                                    class="btnGuardar"
                                    @click="guardar()" 
                                    :disabled="invalid" 
                                    :loading="isSaving"
                                >
                                    Guardar
                                </v-btn>
                            </v-card-actions>
                        </ValidationObserver>
                    </v-card>
                </v-dialog>
            </template>


            <template>
                <v-dialog v-model="dialogWizard" persistent max-width="900px" class="borde-card">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                    </template>
                    <v-card class="borde-card">
                        <ValidationObserver ref="formWizard" v-slot="{ invalid }">
                            <v-card-title class="d-flex justify-end">
                                <div class="background-transparent">
                                    <v-btn
                                        @click="cerrarWizard()"
                                        small
                                        icon
                                        text
                                        :ripple="false" 
                                        class="v-btn-cerrar mr-2"
                                    >
                                        <v-icon class="icono-cerrar"></v-icon>
                                    </v-btn>
                                </div>
                            </v-card-title>

                            <v-card-text class="card_texto">
                                <div id="padre" class="d-flex justify-center my-10" v-if="isLoadingWizard">
                                    <div id="loading">
                                        <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                    </div>
                                </div>
                                
                                <v-container v-if="!isLoadingWizard" grid-list-md id="contenedor">
                                    <div>
                                        
                                        <div>
                                            <span class="titleWizard" v-if="tabs==0">Datos de facturación</span>
                                            <span class="titleWizard" v-if="tabs==1">Tipo de pago</span>
                                        </div>

                                        <v-tabs
                                            v-model="tabs"
                                            fixed-tabs
                                            background-color="transparent"
                                        >
                                            <v-tabs-slider color="transparent"></v-tabs-slider>
                                            
                                            <v-tab  class="pa-0 " >
                                                <span :class="tabs==1?'tabTextDisable':'tabText'" class="tabText"></span>
                                            </v-tab>
                                            <v-tab  class="pa-0 " >
                                                <span :class="tabs==0 ?'tabTextDisable':'tabText'" class="tabText"></span>
                                            </v-tab>
                                            
                                        </v-tabs>

                                        <v-tabs-items v-model="tabs" class="mb-10">
                                            <v-tab-item :key="0">
                                                <v-row class="pa-0 ma-0 mt-4">
                                                    <v-col v-if="rol == 'root'" cols="12" xs="12" sm="12" md="12" class="py-0">
                                                        <v-autocomplete
                                                            outlined
                                                            :items="clientes"
                                                            item-text="nombre"
                                                            item-value="id"
                                                            label="Cliente"
                                                            persistent-hint
                                                            v-model="cliente_value"
                                                            no-data-text="Datos no disponibles"
                                                            readonly
                                                        />
                                                    </v-col>
                                                    <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="py-0">
                                                        <VuetifyMultipleAutocomplete 
                                                            outlined
                                                            :items="itemsRegistrosPatronales"
                                                            item-text="nombre"
                                                            item-value="id"
                                                            @dataMultiple="(data)=>configuracion.array_registro_patronal = data"
                                                            :valorDefecto="configuracion.array_registro_patronal"
                                                            label="Registros patronales"
                                                            ref="multRegistroPatronal"
                                                            readonly
                                                        />
                                                    </v-col>
                                                    <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="py-0">
                                                        <v-text-field 
                                                            outlined
                                                            label="Razón social" 
                                                            class="pa-0 ma-0" 
                                                            v-model="configuracion.razon_social" 
                                                            readonly
                                                        >
                                                        </v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="py-0">
                                                        <v-text-field 
                                                            outlined 
                                                            label="RFC" 
                                                            class="pa-0 ma-0" 
                                                            maxlength="13"
                                                            v-model="configuracion.rfc"
                                                            readonly
                                                        >                                               
                                                        </v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="py-0">
                                                        <v-autocomplete
                                                            outlined
                                                            label="Régimen Fiscal"
                                                            :items="regimenesFiscales"
                                                            item-text="descripcion"
                                                            item-value="id"
                                                            persistent-hint
                                                            v-model="configuracion.SAT_regimen_fiscal_id"
                                                            class="pa-0 ma-0"
                                                            no-data-text="Datos no disponibles"
                                                            readonly
                                                        >
                                                        </v-autocomplete>
                                                    </v-col>
                                                    <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="py-0">
                                                        <v-autocomplete
                                                            outlined
                                                            label="Uso CFDI"
                                                            :items="itemsUsosCFDI"
                                                            item-text="descripcion"
                                                            item-value="id"
                                                            persistent-hint
                                                            v-model="configuracion.SAT_uso_cfdi_id"
                                                            class="pa-0 ma-0"
                                                            no-data-text="Datos no disponibles"
                                                            readonly
                                                        >
                                                        </v-autocomplete>
                                                    </v-col>

                                                    <v-col cols="12" xs="12" sm="6" md="4" class="py-0">
                                                        <v-text-field
                                                            outlined
                                                            label="Código postal"
                                                            v-model="configuracion.codigo_postal"
                                                            v-mask="'#####'"
                                                            readonly
                                                        />
                                                    </v-col>
                                                </v-row>
                                            </v-tab-item>
                                            <v-tab-item :key="1">
                                                <v-row class="pa-0 ma-0 d-flex justify-center">
                                                    <div>

                                                        <template>
                                                            <v-col cols="12" xs="12" sm="12" md="12" style="width:500px">
                                                                
                                                                <VuetifyMoneyValidate
                                                                    outlined
                                                                    label="Costo timbre"
                                                                    rules="required"
                                                                    class="pa-0 ma-0"
                                                                    prefix="$"
                                                                    v-model="itemFactura.costo_timbres"
                                                                    v-bind:options="options"
                                                                />
                                                                <ValidationProvider v-slot="{ errors }" name="IVA" rules="required">
                                                                    <v-text-field
                                                                        outlined
                                                                        label="IVA"
                                                                        class="pa-0 ma-0"
                                                                        v-on:keypress="presicionNumero($event,itemFactura.iva_porcentaje)"
                                                                        oninput="if(this.value > 100 || this.value<0) this.value = 100; this.value=this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                                                        v-model="itemFactura.iva_porcentaje" 
                                                                        suffix="%"
                                                                        :error-messages="errors" 
                                                                        required
                                                                    />
                                                                </ValidationProvider>
                                                                <ValidationProvider v-slot="{ errors }" name="Mes de acumulación" rules="required">
                                                                    <v-autocomplete
                                                                        outlined
                                                                        label="Mes de acumulación"
                                                                        :items="meses"
                                                                        item-text="nombre"
                                                                        item-value="valor"
                                                                        persistent-hint
                                                                        v-model="itemFactura.mes_acomulacion"
                                                                        class="pa-0 ma-0"
                                                                        no-data-text="Datos no disponibles"
                                                                        :error-messages="errors"
                                                                        required
                                                                    >
                                                                    </v-autocomplete>
                                                                </ValidationProvider>
                                                                <ValidationProvider v-slot="{ errors }" name="Año" rules="required">
                                                                    <v-text-field 
                                                                        outlined
                                                                        label="Año" 
                                                                        class="pa-0 ma-0"
                                                                        v-mask="'####'"
                                                                        v-model="itemFactura.annio"
                                                                        :error-messages="errors" 
                                                                        required
                                                                    >
                                                                    </v-text-field>
                                                                </ValidationProvider>
                                                                
                                                                <ValidationProvider v-slot="{ errors }" name="Descripción" rules="required">
                                                                    <v-textarea
                                                                        outlined
                                                                        label="Descripción"
                                                                        rows="2"
                                                                        v-model="itemFactura.descripcion_timbres"
                                                                        :error-messages="errors" 
                                                                        required
                                                                    ></v-textarea>
                                                                </ValidationProvider>
                                                                
                                                            </v-col>
                                                        </template>
                                                    </div>
                                                </v-row>
                                            </v-tab-item>
                                        </v-tabs-items>
                                    </div>
                                </v-container>
                            </v-card-text>
                            <v-card-actions v-if="tabs==0 " class="d-flex justify-end card_accion">
                                <v-btn
                                    class="btnGuardar"
                                    @click="btnSiguiente()" 
                                    :loading="isSaving"
                                >
                                    Siguiente
                                </v-btn>
                            </v-card-actions>
                            <v-card-actions v-if="tabs==1 " class="d-flex justify-end card_accion">
                                <button
                                    class="btnCerrar"
                                    @click="regresar(0)" 
                                    :disabled="isSaving"
                                >
                                    Regresar
                                </button>
                                <v-btn
                                    class="btnGuardar"
                                    @click="guardarWizard()" 
                                    :disabled="invalid" 
                                    :loading="isSaving"
                                >
                                    Guardar
                                </v-btn>
                                <v-btn
                                    class="btnGuardar"
                                    @click="consultar()" 
                                    :disabled="invalid" 
                                    :loading="isSaving"
                                >
                                    Consultar
                                </v-btn>
                            </v-card-actions>
                        </ValidationObserver>
                    </v-card>
                </v-dialog>
            </template>


            <template>
                <v-dialog v-model="dialog_pdf" persistent max-width="900px">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModalPDF" style="display: none;"></v-btn>
                    </template>
                    <v-card>
                        <!-- <div>
                            <v-btn
                                @click="cerrarModalPDF()"
                                small
                                slot="activator"
                                icon
                                text
                                class="v-btn-cerrar pull-right mr-3 mt-3"
                            >
                                <v-icon class="icono-cerrar"></v-icon>
                            </v-btn>
                        </div> -->
                        <div class="d-flex justify-end">
                            <div class="background-transparent">
                                <v-btn
                                    @click="cerrarModalPDF()"
                                    small
                                    icon
                                    text
                                    :ripple="false" 
                                    class="v-btn-cerrar mr-2 mt-2"
                                >
                                    <v-icon class="icono-cerrar"></v-icon>
                                </v-btn>
                            </div>
                        </div>
                        <v-card-title>
                            <span class="titleModal ml-2" v-text="titulo_modal"></span>
                        </v-card-title>
                        <v-card-text class="px-0 pb-0">
                            <v-container grid-list-md>
                                <v-row>
                                    <template v-if="!procesoFinalizado" >
                                        <v-col cols="12" xs="12" sm="12" md="12" class="align-center my-15" align="center" justify="center">
                                            <h3 class="tituloCodigo">Procesando</h3>

                                            <beat-loader
                                                :loading="true"
                                                :color="'#15D411'"
                                                :margin="'2px'"
                                                :radius="'100px'"
                                            ></beat-loader>
                                        </v-col>
                                        
                                    </template>
                                    <template>
                                        <v-col v-show="procesoFinalizado" cols="12"  xs="12" sm="12" md="12">
                                            <iframe src="" id="myIframe"  width='100%' height='700px' allowfullscreen webkitallowfullscreen></iframe>
                                        </v-col>
                                    </template>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </template>

        </v-container>
    </div>
</template>

<script>

//Componentes
import Datatable from '@/components/datatable/Datatable.vue';
import Paquete from '@/components/Paquete';
import ExpandableItem from '@/components/ExpandableItemTimbresIlimitados.vue';
import VuetifyMultipleAutocomplete from "@/components/VuetifyMultipleAutocomplete2.vue";
import VuetifyMoneyValidate from '@/components/VuetifyMoneyValidate.vue';
import BeatLoader from "vue-spinner/src/BeatLoader.vue";

//API's
import apiConfiguracionTimbresIlimitados from '@/api/nominas/configurarTimbresIlimitados';
import catalogoApis from "@/api/catalogos";
import clientesApi from "@/api/clientes";
import apiRegistroPatronal from "@/api/nominas/registroPatronal";
import apiRegimenFiscal from '@/api/nominas/regimenFiscal';

//Utilerias
import Notify from '@/plugins/notify';


export default {
    components: {
        'data-table': Datatable,
        Paquete,
        ExpandableItem,
        VuetifyMultipleAutocomplete,
        VuetifyMoneyValidate,
        BeatLoader,
    },
    data() {
        return {
            tabs: 0,
            columns         : [
                {
                    label: 'Nombre',
                    name: 'nombre',
                    filterable: false
                },
                {
                    label:'Último movimiento',
                    name:'ultimo_movimiento',
                    filterable:false
                },
                {
                    label: 'Opciones',
                    name: 'opciones',
                    align: 'center',
                    filterable: false
                }
            ],
            filters         : {
                cliente_id  : null,
                paginate    : true,
                activo      : true,
            },
            url             : 'cliente-libre-datos/find',
            isLoading       : true,
            isLoadingModal  : false,
            paginationData  : {
                numberItems : 10,
                numberPage  : 1
            },
            configuracion           : {
                id                      : null,
                cliente_id              : null,
                array_registro_patronal : [],
                razon_social            : null,
                rfc                     : null,
                codigo_postal           : null,
                SAT_regimen_fiscal_id   : null,
                SAT_uso_cfdi_id         : null,
            },
            datosLogin                  : null,
            rol                         : null,
            cliente_value               : null,
            accion                      : null,
            tituloModal                 : null,
            dialog                      : false,
            isSaving                    : false,
            imagen                      : '',
            regimenesFiscales           : [],
            itemsUsosCFDI               : [],


            dialogWizard                : false,
            isLoadingWizard             : false,
            loadingPaquetes             : false,
            itemsPaquetes               : [],
            tipoPago                    : 'transferencia',
            archivo                     : null,
            busca_cp                    : null,
            estado                      : "",
            municipio                   : "",
            colonias                    : [],
            clientes                    : [],
            itemsRegistrosPatronales    : [],

            itemFactura: {
                cliente_id                      : null,
                cliente_libre_facturacion_id    : null,
                array_registro_patronal         : [],
                tipo_pago                       : "TRANSFERENCIA",
                costo_timbres                   : 0,
                iva_porcentaje                  : 0,
                descripcion_timbres             : null,
                mes_acomulacion                 : null,
                annio                           : null,
                operacion                       : null,
            },
            options: {
                locale: "es-MX",
                prefix: "$",
                suffix: "",
                length: 11,
                precision: 2
            },
            meses: [
                {
                    nombre:"Enero",
                    valor:"Enero"
                },
                {
                    nombre:"Febrero",
                    valor:"Febrero"
                },
                {
                    nombre:"Marzo",
                    valor:"Marzo"
                },
                {
                    nombre:"Abril",
                    valor:"Abril"
                },
                {
                    nombre:"Mayo",
                    valor:"Mayo"
                },
                {
                    nombre:"Junio",
                    valor:"Junio"
                },
                {
                    nombre:"Julio",
                    valor:"Julio"
                },
                {
                    nombre:"Agosto",
                    valor:"Agosto"
                },
                {
                    nombre:"Septiembre",
                    valor:"Septiembre"
                },
                {
                    nombre:"Octubre",
                    valor:"Octubre"
                },
                {
                    nombre:"Noviembre",
                    valor:"Noviembre"
                },
                {
                    nombre:"Diciembre",
                    valor:"Diciembre"
                }
            ],
            dialog_pdf: false,
            procesoFinalizado: false,
            titulo_modal: "",


        }
    },
    watch: {
        cliente_value: function(val) {
            this.getRegistrosPatronales(val);
        },
        busca_cp: function(val){
            if(val != null){
                if(val.length == 5){
                    this.busca_codigos();
                }
                else{
                    this.estado = "";
                    this.municipio = "";
                    this.colonias = [];
                }
            }
        }
    },
    computed:{
        montoTotal(){

            if(this.itemPago.paquetePrecio == null || this.itemPago.iva_porcentaje == null) {
                return;
            }

            return parseFloat(this.itemPago.paquetePrecio) + (parseFloat(this.itemPago.iva_porcentaje) * parseFloat(this.itemPago.paquetePrecio) / 100)
        },
        iva(){
            if(this.itemPago.paquetePrecio == null || this.itemPago.iva_porcentaje == null) {
                return;
            }
            return parseFloat(this.itemPago.iva_porcentaje) * parseFloat(this.itemPago.paquetePrecio) / 100
        }
    },

    methods: {
        open(){
            document.getElementById("fileDocument").click();
        },
        getPaginationInfo(data) {
            switch(data.type) {
                case "page":
                    this.paginationData.numberPage = data.value
                    break;

                case "itemsToShow":
                    this.paginationData.numberItems = data.value
                    break;
            }
            this.isLoading = true;
        },
        getUsoCFDI(){
            let parametros = {
                activo      : true, 
                paginate    : false,
                order       : 'asc'
            }
            apiConfiguracionTimbresIlimitados.getUsoCFDI(parametros).then(response =>{
                this.itemsUsosCFDI = response.data;
            })
            .catch(err => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar el catálogo Usos CFDI");
            });
        },
        getRegimenesFiscales(){
            let parametros = {
                activo      : true, 
                paginate    : false,
                order       : 'asc'
            }
            apiRegimenFiscal.find(parametros).then(response =>{
                this.regimenesFiscales = response.data;
            })
            .catch(err => {
                console.error(err);
                Notify.ErrorToast(err.response.data.error);
            });
        },
        async getClientes() {
            let param = { 
                activo: true, 
                paginate: false 
            };
            await clientesApi.getClientes(param).then(response => {
                this.clientes = response.data;
            })
            .catch(err => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los clientes");
            });
        },
        async getRegistrosPatronales(clienteId) {
            if(clienteId == null){
                return;
            }

            let param = { 
                    activo: true, 
                    paginate: false, 
                    cliente_id: clienteId 
                };
            await apiRegistroPatronal.find(param).then(response => {
                this.itemsRegistrosPatronales = response.data;
            })
            .catch(err => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los registros patronales.");
            });
            
        },
        busca_codigos() {
            let self = this;
            let params = {
                activo: true,
                nombre: this.busca_cp,
                include_padres: true,
            };
            catalogoApis.getCP(params).then((response) => {
                let result = response.data;
                if (result.length == 0) {
                    Notify.ErrorToast("No se encontro el Código postal.");
                    this.estado = "";
                    this.municipio = "";
                    this.colonias = [];
                    return;
                }
                this.estado = result[0].nomb_estado;
                this.municipio = result[0].nomb_mun;
                this.configuracion.estado_id = result[0].estado_id;
                this.configuracion.municipio_id = result[0].municipio_id;
                this.configuracion.cp_id = result[0].id;

                catalogoApis.getColonias({ codigo_postal_id: result[0].id, order: "asc", paginate: false }).then((response) => {
                    self.colonias = response.data;
                    if (self.accion == "update") {
                        self.finaliza_carga = true;
                    }
                })
                .catch((e) => {
                        Notify.ErrorToast(
                            "Se presento un problema al cargar las colonias."
                        );
                    return;
                });
            })
            .catch((e) => {
                console.log(e);
                Notify.ErrorToast(
                    "Ocurrió un error al cargar los estados y ciudades."
                );
            });
        },
        async getCP(id) {
            await catalogoApis.getCodigoPostal(id).then((response) => {
                this.busca_cp = response.nombre;
                this.busca_codigos();
            })
            .catch((err) => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los codigos postales.");
            });
        },
        async abrirModal(accion, data = null){
            this.accion         = accion;
            this.isLoadingModal = true;
            this.getRegimenesFiscales();
            this.getUsoCFDI();

            if(this.rol == 'root'){
                this.getClientes();
            }
            else {
                this.cliente_value = this.datosLogin.cliente_id;
            }

            if(accion == "add"){
                this.tituloModal    = "Nuevos datos para facturación";
                this.imagen         = "/static/icon/configuracionTimbres.png";
                
            } else {
                this.tituloModal        = "Modificar datos para facturación";
                this.imagen             = "/static/icon/configuracionTimbres.png";
                this.configuracion.id                       = data.id;
                this.cliente_value                          = data.cliente_id;
                this.configuracion.registro_patronal_id     = data.registro_patronal_id;
                this.configuracion.razon_social             = data.razon_social;
                this.configuracion.rfc                      = data.rfc;
                this.configuracion.SAT_regimen_fiscal_id    = data.SAT_regimen_fiscal_id;
                this.configuracion.SAT_uso_cfdi_id          = data.SAT_uso_cfdi_id;
                this.configuracion.codigo_postal            = data.codigo_postal;
            }
            this.isLoadingModal  = false;
            setTimeout(function(){ document.getElementById("btnModal").click(); }, 100);
        },
        async abrirModalUpdate(data){
            this.accion         = 'update';
            this.isLoadingModal = true;
            this.getRegimenesFiscales();
            this.getUsoCFDI();


            if(this.rol == 'root'){
                this.getClientes();
            }
            else {
                this.cliente_value = this.datosLogin.cliente_id;
            }

            if(this.accion == "add"){
                this.tituloModal    = "Nuevos datos para facturación";
                this.imagen         = "/static/icon/configuracionTimbres.png";
                
            } else {
                this.tituloModal        = "Modificar datos para facturación";
                this.imagen             = "/static/icon/configuracionTimbres.png";
                this.configuracion.id                       = data.id;
                this.cliente_value                          = data.cliente_id;
                this.configuracion.array_registro_patronal  = data.array_registro_patronal;
                this.configuracion.razon_social             = data.razon_social;
                this.configuracion.rfc                      = data.rfc;
                this.configuracion.SAT_regimen_fiscal_id    = data.SAT_regimen_fiscal_id;
                this.configuracion.SAT_uso_cfdi_id          = data.SAT_uso_cfdi_id;
                this.configuracion.codigo_postal            = data.codigo_postal;
            }
            this.isLoadingModal  = false;
            setTimeout(function(){ document.getElementById("btnModal").click(); }, 100);
        },
        cerrarModal() {
            this.dialog     = false;
            this.isSaving   = false;
            this.resetValues();
        },
        resetValues() {
            this.cliente_value = null;
            this.busca_cp = null;
            this.estado = null;
            this.municipio = null;
            this.tabs = 0;
            this.configuracion      = {
                id                      : null,
                cliente_id              : null,
                array_registro_patronal : [],
                razon_social            : null,
                rfc                     : null,
                codigo_postal           : null,
                SAT_regimen_fiscal_id   : null,
                SAT_uso_cfdi_id         : null, 
            }
            this.archivo = null;

            this.$nextTick(() => {

                if(this.$refs.form){
                    this.$refs.form.reset();
                }
                if(this.$refs.formWizard){
                    this.$refs.formWizard.reset();
                }
            });
        },
       
        guardar() {
            this.$refs.form.validate().then(success => {
                this.isSaving = true;
                if(!success) {
                    this.isSaving = false;
                    return;
                }
                
                this.configuracion.cliente_id = this.cliente_value;
                
                if(this.accion === "add") {
                    apiConfiguracionTimbresIlimitados.guardarDatos(this.configuracion).then((response) => {
                        Notify.Success("Operación exitosa", "Los datos de facturación se guardaron satisfactoriamente.");
                        this.$refs.tabla.getData();
                        this.cerrarModal();
                        this.$nextTick(() => {
                            this.$refs.form.reset();
                        });
                    })
                    .catch(err => {
                        this.isSaving = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
                else if(this.accion === "update") {
                    apiConfiguracionTimbresIlimitados.updateDatos(this.configuracion).then((response) => {
                        Notify.Success("Operación exitosa", "Los datos de facturación se actualizaron satisfactoriamente.");
                        this.$refs.tabla.getData();
                        this.cerrarModal();
                        this.$nextTick(() => {
                            this.$refs.form.reset();
                        });
                    })
                    .catch(err => {
                        this.isSaving = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
            });
        },
        detalle(registroTimbreIlimitado){
            this.$session.remove("registroTimbreIlimitado");
            this.$session.set('registroTimbreIlimitado',registroTimbreIlimitado);
            this.$router.push('/detalleTimbreIlimitados');
        },
        async facturar(data){

            this.isLoadingWizard = true;
            this.dialogWizard = true;
            /* Tab para mostrar informacion datos de facturación */
            this.getUsoCFDI();
            this.getRegimenesFiscales();

            if(this.rol == 'root'){
                this.getClientes();
            }
            else {
                this.cliente_value = this.datosLogin.cliente_id;
            }
            this.configuracion.id                       = data.id;
            this.cliente_value                          = data.cliente_id;
            this.configuracion.array_registro_patronal  = data.array_registro_patronal;
            this.configuracion.razon_social             = data.razon_social;
            this.configuracion.rfc                      = data.rfc;
            this.configuracion.SAT_regimen_fiscal_id    = data.SAT_regimen_fiscal_id;
            this.configuracion.SAT_uso_cfdi_id          = data.SAT_uso_cfdi_id;
            this.configuracion.codigo_postal            = data.codigo_postal;

            this.itemFactura.cliente_id = data.cliente_id;
            this.itemFactura.cliente_libre_facturacion_id = data.id;
            this.itemFactura.array_registro_patronal = data.array_registro_patronal;
            this.itemFactura.tipo_pago = "TRANSFERENCIA";

            this.isLoadingWizard = false;
        },
        btnSiguiente(){
            this.tabs=1
        },
        regresar(data){
            this.tabs = data;
        },
        guardarWizard(){

            this.$refs.formWizard.validate().then(success => {
                this.isSaving = true;
                if(!success) {
                    this.isSaving = false;
                    return;
                }
                let parametros = { 
                    cliente_id                      : this.itemFactura.cliente_id,
                    cliente_libre_facturacion_id    : this.itemFactura.cliente_libre_facturacion_id,
                    array_registro_patronal         : this.itemFactura.array_registro_patronal,
                    tipo_pago                       : "TRANSFERENCIA",
                    costo_timbres                   : this.itemFactura.costo_timbres,
                    iva_porcentaje                  : this.itemFactura.iva_porcentaje,
                    descripcion_timbres             : this.itemFactura.descripcion_timbres,
                    mes_acomulacion                 : this.itemFactura.mes_acomulacion,
                    annio                           : this.itemFactura.annio,
                    operacion                       : "GUARDAR"
                };

                apiConfiguracionTimbresIlimitados.guardarFactura(parametros).then((response) => {
                    Notify.Success("Operación exitosa", "Se solicitó la compra del paquete satisfactoriamente.");
                    
                    this.cerrarWizard();
                    this.isSaving = false;
                    this.$refs.tabla.getData();
                })
                .catch(err => {
                    this.isSaving = false;
                    if(typeof err.response.data.error === 'string'){
                        Notify.ErrorToast(err.response.data.error);
                    }else{
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;

                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }
                        Notify.ErrorToast(leyenda);
                    }
                });

            })

        },
        consultar(){
            this.abrirModalPDF();
        },
        abrirModalPDF(){
            self = this;
            this.titulo_modal = "Vista previa";
            const axios = require("axios");
            let url = "facturacion-timbres-libres/estado-cuenta";
            let parametros = { 
                cliente_id                      : this.itemFactura.cliente_id,
                cliente_libre_facturacion_id    : this.itemFactura.cliente_libre_facturacion_id,
                array_registro_patronal         : this.itemFactura.array_registro_patronal,
                tipo_pago                       : "TRANSFERENCIA",
                costo_timbres                   : this.itemFactura.costo_timbres,
                iva_porcentaje                  : this.itemFactura.iva_porcentaje,
                descripcion_timbres             : this.itemFactura.descripcion_timbres,
                mes_acomulacion                 : this.itemFactura.mes_acomulacion,
                annio                           : this.itemFactura.annio,
                operacion                       : "CONSULTAR"
            };
            this.dialog_pdf = true;
            this.procesoFinalizado  = false;
            try {
                axios({
                    method: "post",
                    url: url,
                    responseType: "blob",
                    data: parametros,
                }).then((response) =>{
                    let fileURL = URL.createObjectURL(response.data);
                    $('#myIframe').attr('src', fileURL); 
                    $('iframe').on("load", function () {
                        $(this).contents().find('img').css({width: '100%'});
                    });
                    this.procesoFinalizado  = true;
                }).catch((err) => {
                    this.dialog_pdf = false;
                    Notify.ErrorToast('No se puede mostrar el archivo.');
                });
            } catch (error) {
                this.dialog_pdf = false;
                console.log(error);
            }
        },
        cerrarModalPDF(){
            this.dialog_pdf = false;
        },
        presicionNumero(event, valor){
            if (/\.\d{3}/.test(valor)) 
                return event.preventDefault();
        },
        cerrarWizard(){
            this.dialogWizard = false;
            this.itemFactura= {
                cliente_id                      : null,
                cliente_libre_facturacion_id    : null,
                array_registro_patronal         : [],
                tipo_pago                       : "TRANSFERENCIA",
                costo_timbres                   : 0,
                iva_porcentaje                  : 0,
                descripcion_timbres             : null,
                mes_acomulacion                 : null,
                annio                           : null,
                operacion                       : null,
            };
            this.cerrarModal();
        },
        formatNum(numero){
            let num = parseFloat(numero)
            const formatter = new Intl.NumberFormat('es-MX', {
                style: 'currency',
                currency: 'MXN',
            })
            return formatter.format(num);
        },
        redireccionar(){
            this.$router.push('/dashboard');
        }
    },
    created(){
        this.datosLogin = this.$session.get("usuario");
        this.rol = this.datosLogin.rol.name;

        if(this.rol != 'root'){
            this.filters.cliente_id = this.datosLogin.cliente_id;
        }
    },
    mounted(){
        this.isLoading = false;
    },
}
</script>

<style scoped>
    .modal-subtitle {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #1E2144;
    }

    .tabText{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 17px;
        color: #1E2245;
        text-transform: none;
        background-color: #2DB9D1;
        height: 6px;
        width: 100%;
        margin: 0;
        border-radius:6px

    }
    .tabTextDisable{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 17px;
        color: #BDBDBD;
        text-transform: none;
        background-color: #828282;
        height: 1px;
        width: 100%;
        margin: 0;

    }

    .titleWizard {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #1E2245;
    }

    .v-btn-cerrar:hover:before {
      background-color: transparent;
      
    }
    .v-btn-cerrar:before {
        display: none;
    }

    .transfencia {
        border: 1px solid #E0E0E0;
        border-radius: 10px;
    }
    .tranferencia-contenedor {
        margin: 20px;
    }
    .transferencia-titulo-general {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #1E2144;
    }
    .transferencia-titulo {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #828282;

    }
    .transferencia-descripcion {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #4F4F4F;
    }


    .div-total {
        border: 1px solid #E0E0E0;
        border-radius: 10px;
    }
    .div-total-contenedor {
        margin: 20px;
    }
    .div-total-tabla {
        width: 100%;
    }
    .div-total-tabla-td-descripcion {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #4E4E4E;
    }
    .div-total-tabla-td-valor {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        text-align: right;
        color: #4E4E4E;
    }
    .div-total-tabla-td-valor2 {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        text-align: right;
        color: #1E2245;
    }
    .div-total-tabla-td-valor3 {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        text-align: right;
        color: #1E2245;
    }
    .div-total-tabla-td-null {
        text-align: center;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        color: #1E2245;
    }


</style>
